import React from "react";
import {graphql} from "gatsby";
import {withPrismicPreview} from "gatsby-plugin-prismic-previews";
import {PrismicRichText, SliceZone} from "@prismicio/react";
import Metadata from "../../components/Metadata";
import HeroSection from "./components/Hero/HeroSection";
import ServicesSection from "./components/Services/ServicesSection";
import IndustriesSection from "./components/Industries/IndustriesSection";
import AboutUsSection from "./components/AboutUs/AboutUsSection";
import ContactUs from "./components/ContactUs/ContactUs";

const HomePageTemplate = ({data}) => {
    const pageData = data?.prismicHomePage?.dataRaw;

    console.log(pageData)

    const hero = {
        hero_title : pageData?.hero_title,
        hero_description : pageData?.hero_description,
        hero_button_text : pageData?.hero_button_text,
        hero_side_image_src : pageData?.hero_side_image.url,
        hero_side_image_alt : pageData?.hero_side_image.alt
    }
    const aboutUs = {
        au_title : pageData?.au_title,
        au_description : pageData?.au_description,
        au_image : pageData?.au_image.url
    }
    const industries = {
        indus_title: pageData?.indus_title,
        indus_image : pageData?.indus_image.url,
        indus_description : pageData?.indus_description,
        indus_card : pageData?.indus_card
    }
    const contactUs = {
        cu_title: pageData?.cu_title,
        cu_button_text : pageData?.cu_button_text
    }
    const service = {
        s_title: pageData?.s_title,
        s_card : pageData?.s_card
    }

    return (
        <>
            <HeroSection data={hero}/>
            <ServicesSection data={service}/>
            <IndustriesSection data={industries}/>
            <AboutUsSection data={aboutUs}/>
            <ContactUs data={contactUs}/>
        </>
    );
};

export default withPrismicPreview(HomePageTemplate);

export const Head = ({data}) => {
    return <Metadata pageData={data?.prismicHomePage?.dataRaw}/>;
};

export const query = graphql`
  query homePageQuery($id: String) {
    prismicHomePage(id: { eq: $id }) {
      _previewable
      dataRaw
    }
  }
`;

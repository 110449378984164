import React,{useState} from "react";
import StyledComponent from "../../../../components/StyledComponent";
import {Col, Form, Row} from "react-bootstrap";
import {homePageContactSchema} from "../../../../utils/validationSchemas";
import StyledFormikInput from "../../../../components/Input/StyledFormikInput";
import StyledButton from "../../../../components/Button/StyledButton";
import StyledFormikMessage from "../../../../components/StyledFormikMessage";
import ContactImg  from "../../../../images/icons/contactImg.svg"
import {Formik} from "formik";
import axios from "axios";
import * as style from './index.module.scss';
import {PrismicRichText} from "@prismicio/react";


const ContactUs = ({data}) => {

    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);

    return (
        <section id="contactUs" className={style.sectionBox}>
            <div className="section-center">
                <section>
                    <Row>
                        <Col>
                            <PrismicRichText field={data?.cu_title}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <Formik
                                initialValues={{
                                    firstName: "",
                                    lastName: "",
                                    email: "",
                                    service: "",
                                    phone: "",
                                }}
                                validationSchema={homePageContactSchema}
                                onSubmit={async (values, {setStatus}) => {

                                    setStatus({});
                                    // await new Promise((r) => setTimeout(r, 2000));
                                    await axios
                                        .post("/api/send-mail", {
                                            fields: [
                                                {key: "First Name", value: values.firstName},
                                                {key: "Last Name", value: values.lastName},
                                                {key: "Email", value: values.email},
                                                {key: "Contact Number", value: values.phone},
                                                {key: "Service", value: values.service},
                                            ],
                                        })
                                        .then(() => {
                                            setSuccess(true);
                                            setStatus({
                                                success: true,
                                                type: "success",
                                                message:
                                                    "Successfully sent. One of our agents will get back to you soon.",
                                            });
                                        })

                                        .catch(() => {
                                            setFail(true);
                                            setStatus({
                                                success: false,
                                                type: "danger",
                                                message: "Failed to send the message. Please retry.",
                                            });
                                        });
                                }}
                            >
                                {({handleSubmit, isSubmitting, status, dirty, ...others}) => {
                                    return (
                                        <Form onSubmit={handleSubmit}>
                                            <Row>
                                                <Col xs={"6"} className={style.formInputCelLeft}>
                                                    <StyledFormikInput
                                                        name={"firstName"}
                                                        type={"text"}
                                                        label={"Your first name*"}
                                                    />
                                                </Col>
                                                <Col xs={"6"} className={style.formInputCelRight}>
                                                    <StyledFormikInput
                                                        name={"lastName"}
                                                        type={"text"}
                                                        label={"Your last name"}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={"6"} className={style.formInputCelLeft}>
                                                    <StyledFormikInput
                                                        name={"email"}
                                                        type={"text"}
                                                        label={"Your email address*"}
                                                    />
                                                </Col>
                                                <Col xs={"6"} className={style.formInputCelRight}>
                                                    <StyledFormikInput
                                                        name={"phone"}
                                                        type={"text"}
                                                        label={"Your telephone number*"}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={"6"} className={style.formInputCelLeft}>
                                                    <StyledFormikInput
                                                        name={"company"}
                                                        type={"text"}
                                                        label={"Your company name"}
                                                    />
                                                </Col>
                                                <Col xs={"6"} className={style.formInputCelRight}>
                                                    <StyledFormikInput
                                                        name={"service"}
                                                        type={"text"}
                                                        as={"select"}
                                                        label={"Select service"}
                                                        defaultValue={""}
                                                    >
                                                        <option value={""}>Please select</option>
                                                        <option value="Maintenance Services">Maintenance Services</option>
                                                        <option value="Environmental Services">Environmental Services</option>
                                                        <option value="Minor Works">Minor Works</option>
                                                        <option value="Staffing Services">Staffing Services</option>
                                                        <option value="Other">Other</option>
                                                    </StyledFormikInput>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={"12"}>
                                                    <StyledFormikInput
                                                        name={"message"}
                                                        as={"textarea"}
                                                        type={"text"}
                                                        label={"How can we help you?"}
                                                    />
                                                </Col>
                                            </Row>
                                            <StyledButton
                                                title={data.cu_button_text}
                                                progress={isSubmitting}
                                                disabled={status?.success}
                                            />
                                            <StyledFormikMessage status={status}/>
                                        </Form>
                                    );
                                }}
                            </Formik>
                            {/*<div className={style.contactBox}>*/}
                                {/*<div>*/}
                                    {/*<img src={ContactImg}/>*/}
                                {/*</div>*/}
                                {/*<div>*/}
                                    {/*<p>Speak with an <span>expert</span></p>*/}
                                    {/*<p>(212) 913-9141</p>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        </Col>
                        <Col lg={6}>
                            <div className={style.mapArea}>
                                {/*<iframe className={style.gmap_iframe} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"*/}
                                        {/*src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=AT Digital pvt, maharagma &amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>*/}

                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.161219921608!2d79.9034425746476!3d6.871277393127439!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae251db96c3aee1%3A0x8ada6299096ba113!2sENV%20Engineering%20Enterprises%20Lanka%20(Pvt)%20Ltd!5e0!3m2!1sen!2slk!4v1683614304645!5m2!1sen!2slk"
                                    className={style.gmap_iframe} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                            </div>
                        </Col>
                    </Row>
                </section>
            </div>
        </section>
    );
};

export default ContactUs;
